import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { findRouteData } from 'gain-web/lib/router/find-route-data';
import { UserContext } from 'gain-web/shared-modules/user/user-context';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserContextService {
  private _value$ = new ReplaySubject<UserContext | undefined>(1);
  public get value(): UserContext | undefined {
    const contextData = findUserContext(this._router.routerState.root.snapshot);
    return contextData != null ? new UserContext(contextData) : undefined;
  }

  public getValueOrThrow(): UserContext {
    const v = this.value;
    if (v == null) {
      throw new Error('attempted to get undefined user context');
    }
    return v;
  }

  public get valueChanges(): Observable<UserContext | undefined> {
    return this._value$.asObservable();
  }
  constructor(private _router: Router) {
    this._router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this._value$.next(this.value);
      });
  }
}

export function getUserContext(
  route: ActivatedRouteSnapshot,
): ApiClient.IGainUserContextDto {
  const userContext = findUserContext(route);
  if (userContext == null) {
    throw new Error('Unable to find user context in route tree');
  }
  return userContext;
}

export function findUserContext(
  route: ActivatedRouteSnapshot,
): ApiClient.IGainUserContextDto | undefined {
  return findRouteData<ApiClient.IGainUserContextDto>(route, 'userContext');
}

export async function findOrFetchUserContext(
  route: ActivatedRouteSnapshot,
  userContextService: ApiClient.UserContextService,
): Promise<ApiClient.IGainUserContextDto> {
  let userContext = findUserContext(route);
  if (userContext == null) {
    const ucr = await userContextService.getUserContext().toPromise();
    userContext = ucr.userContext;
    route.data = {
      ...route.data,
      userContext,
    };
  }
  return userContext;
}
